import React from "react"
// import AppStore from "../../../assets/images/store/app-store.svg"
// import PlayStore from "../../../assets/images/store/google-play.svg"
import H2 from "../../core/H2"

export const AndriodIosBtn = ({ AppStoreBlock, theme }) => {
  const { androidPlayStoreLink, iosAppStoreLink } = AppStoreBlock

  return (
    <div className="bg-transparent mt-1"> 
      <div className="max-w-screen-xl mx-auto py-12 sm:py-16 px-4 sm:px-6 lg:px-8 ">
        <div
          className="m-6 mx-auto flex flex-col justify-center text-center items-center"
          data-aos="fade-up"
        >
          <H2 title={`Available On`} />
          {theme && (
            <hr
              className={`h-1 w-40 mt-5 border-0`}
              style={{ backgroundColor: theme }}
            />
          )}
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:mt-20 mt-12">
          <div
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:justify-self-end flex justify-center "
            data-aos="fade-up"
          >
            <a
              href={iosAppStoreLink}
              key={Math.random()}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/app-store-1.png"
                alt="AppStore"
                // title="AppStore"
                width="246px"
                height="73px"
                loading="lazy"
              />
            </a>
          </div>
          <div
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:justify-self-start flex justify-center"
            data-aos="fade-up"
          >
            <a
              href={androidPlayStoreLink}
              key={Math.random()}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/google-play.png"
                alt="PlayStore"
                // title="PlayStore"
                width="246px"
                height="73px"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      </div>
  )
}

export default AndriodIosBtn
